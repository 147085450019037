import { mapGetters } from 'vuex'

/* Note: This mixin can be used anywhere b-pagination-nav or b-pagination is used to navigate a
  processed PDF. See the following files for examples:
  • src/pages/Document.vue (used with <b-pagination-nav>)
  • src/components/StagelineV2/slides/documentUpload/DocumentUploadSlide.vue (used with <b-pagination>)
 */
export const documentViewMixin = {
  data() {
    return {
      doc: '',
      imageURL: '',
      pageNumber: this.$route.params.page ? Number(this.$route.params.page) : 1,
      pageLoaded: false,
    }
  },
  computed: {
    ...mapGetters('documents', [
      'pages',
    ]),
  },
  methods: {
    async fetchDownloadUrl() {
      try {
        const id = this.$route.params.id || this.doc?.id
        const result = await this.$store.dispatch('documents/getDownloadUrls', {
          ids: [id],
        })
        return result.data.url
      } catch (error) {
        return false
      }
    },
    async download() {
      try {
        const url = await this.fetchDownloadUrl()
        if (url) {
          window.open(url, '_blank')
        }
      } catch (error) {
        this.errorToast('Error', error)
      }
    },
    async print() {
      const url = await this.fetchDownloadUrl()
      if (url) {
        const printWindow = window.open(url, '_blank')
        setTimeout(printWindow.window.print(), 1000)
      }
    },
    async loadPage() {
      if (this.doc.pages && this.doc.pages.length > 0) {
        this.pageLoaded = false
        try {
          const id = this.$route.params.id || this.doc?.id
          const pageId = this.doc.pages[this.pageNumber - 1]
          if (!this.pages[pageId]?.url) {
            await this.$store.dispatch('documents/getPageURL', { id, pageId })
          }
          this.imageURL = this.pages[pageId].url
          await this.rotatePage()
          this.pageLoaded = true

        } catch (error) {
          this.errorToast('Error', error)
        }
      } else {
        this.errorToast('Error', 'An error occurred rendering this document\'s pages, please download it to view it')
      }
      this.pageLoaded = true
    },
    async rotatePage() {
      try {
        const id = this.$route.params.id || this.doc?.id
        const pageId = this.doc.pages[this.pageNumber - 1]
        if (!this.pages[pageId]?.rotation) {
          await this.$store.dispatch('documents/getPageRotation', { id, pageId })
        }
        const rotation = this.pages[pageId].rotation
        const img = document.getElementsByClassName('client-document-page')[0]
        if (img) {
          if (!img.style) img.style = {}

          //Reset rotation specific styling on page change
          img.style.maxWidth = ''
          img.parentElement.style.height = ''

          img.style.transform = `rotate(${rotation}deg)`
          await new Promise(resolve => setTimeout(resolve, 0))
          await img.decode()
          if (( rotation == 90 ) || ( rotation == 270 ) || ( img.width > img.height )) {
            img.style.maxWidth  = '100%'
            img.parentElement.style.height = (img.width * 1.1) + 'px'
          }
        }
      } catch (error) {
        console.log(error)
      }
    },
    async pageChanged(value) {
      this.pageNumber = value
      await this.loadPage()
    },
    scrollUp() {
      window.scroll({
        top: 0,
        left: 0,
      })
    },
  },
}
